import revive_payload_client_4sVQNw7RlN from "/var/www/taptatti/nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/taptatti/nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/taptatti/nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/var/www/taptatti/nuxt/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/var/www/taptatti/nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/taptatti/nuxt/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/taptatti/nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/taptatti/nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/taptatti/nuxt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/taptatti/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/taptatti/nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import templates_plugin_8448ae3c_YQD5x1XZPg from "/var/www/taptatti/nuxt/.nuxt/templates.plugin.8448ae3c.mts";
import plugin_8SbxDRbG6Y from "/var/www/taptatti/nuxt/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/var/www/taptatti/nuxt/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import nuxt3_plugin_BdfRvWAAOY from "/var/www/taptatti/nuxt/node_modules/vue-yandex-maps/dist/plugins/nuxt3-plugin.js";
import maska_UHaKf2z1iQ from "/var/www/taptatti/nuxt/plugins/maska.ts";
import scrollToTop_qhSEFOqbwG from "/var/www/taptatti/nuxt/plugins/scrollToTop.ts";
import toast_ysMjUcU7eJ from "/var/www/taptatti/nuxt/plugins/toast.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  templates_plugin_8448ae3c_YQD5x1XZPg,
  plugin_8SbxDRbG6Y,
  i18n_yfWm7jX06p,
  nuxt3_plugin_BdfRvWAAOY,
  maska_UHaKf2z1iQ,
  scrollToTop_qhSEFOqbwG,
  toast_ysMjUcU7eJ
]